
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import StatusCell from '@/components/dataTables/cell/StatusCell.vue'

@Component({
  components: { StatusCell, Row: () => import('../simple.vue') },
})
  export default class RowStatus extends Vue {
  @Prop({ type: [String, Number, Object, Array] }) value!: any
  @Prop({ type: String }) label!: string;
  }
